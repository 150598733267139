/** @format */

import { isAppHost } from 'core/src/app-host';

function appLoader(hostname: string, path: string) {
	if (isAppHost(hostname, 'chat')) {
		return import(
			/* webpackChunkName: "chat" */
			'chat/src'
		);
	}

	if (isAppHost(hostname, 'launch')) {
		return import(
			/* webpackChunkName: "launch" */
			'launch/src'
		);
	}

	if (isAppHost(hostname, 'schedule')) {
		// Temporary hack to detect which app should be loaded, this should work for now so we can test
		// the deployment of the zendesk app, most likely this will be reworked when we do the wrapper app
		if (path.includes('/zendesk')) {
			return import(
				/* webpackChunkName: "zendesk" */
				`zendesk/src`
			);
		}

		if (path.includes('/api-docs-internal-v1')) {
			return import(
				/* webpackChunkName: "api-docs-internal-v1" */
				`api-docs/src/internal-v1`
			);
		}

		if (path.includes('/api-docs-v1')) {
			return import(
				/* webpackChunkName: "api-docs-external-v1" */
				`api-docs/src/external-v1`
			);
		}

		if (path.includes('/chat-client-dropin')) {
			return import(
				/* webpackChunkName: "chat-client-dropin" */
				`chat-customer-client/src/dropin`
			);
		}

		if (path.includes('/chat-customer-client')) {
			return import(
				/* webpackChunkName: "chat-customer-client" */
				`chat-customer-client/src`
			);
		}

		return import(
			/* webpackChunkName: "schedule" */
			`schedule/src`
		);
	}

	return import(
		/* webpackChunkName: "launch" */
		'launch/src'
	);
}

appLoader(window.location.host, window.location.pathname);
