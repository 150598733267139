/**
 *  Detects if the given hostname should render the given `app`. Uses the
 *  leading subdomain to determine which app to use.
 *
 *  Example:
 *
 *    isAppHost('schedule.happytools.dev', 'schedule');
 *    // => true
 *
 *    isAppHost('chat-2344.builds.happytools.dev', 'chat');
 *    // => true
 *
 *    isAppHost('chat.builds.happytools.dev', 'schedule');
 *    // => false
 *
 * @format
 */

export function isAppHost(host: string, app: string) {
	const [domain] = host.split(':');
	const domains = domain.split('.');
	if (domain === 'localhost') {
		return localStorage.getItem('app') === app;
	}
	if (domains[0].indexOf(app) === 0) {
		return true;
	}
	return false;
}

/**
 * Utility method for determining an App's hostname to navigate between
 * apps within the same build.
 *
 * Example using local development:
 *
 *   switchAppHost('chat', 'schedule.local.happytools.dev');
 *   // => 'chat.local.happytools.dev'
 *
 * Example using PR builds on staging:
 *   switchAppHost('chat', 'schedule-2455.builds.happytools.dev');
 *   // => 'chat-2455.builds.happytools.dev'
 *
 * Primarily used by `apps/launcher`.
 */
export function switchAppHost(appSlug: string, currentHost: string) {
	const [domain, port] = currentHost.split(':', 2);
	if (domain === 'localhost') {
		localStorage.setItem('app', appSlug);
		return `localhost:${port}`;
	}
	const [appBuild, ...rest] = domain.split('.');
	const [, ...build] = appBuild.split('-');
	const destinationSlug = [appSlug, ...build].join('-');

	return [destinationSlug, ...rest].join('.') + (port ? ':' + port : '');
}
